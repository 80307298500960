export const DISPLAY_OPTION_FIELD_INPUTS = {
  SIGNUP: "signup",
  EDIT_PROFILE: "edit_profile",
  PROFILE_VIEW: "profile_view",
  COMMUNITY_VIEW: "community_view",
} as const;

export const PREVIEW_SECTIONS = {
  SIGNUP: "signup",
  EDIT_PROFILE: "edit_profile",
  PROFILE_VIEW: "profile_view",
} as const;

export const VISIBILITY_OPTIONS_FOR_FORMS = {
  SIGNUP: "signup",
  EDIT_PROFILE: "edit_profile",
} as const;

export const VISIBILITY_OPTIONS_FOR_COMMUNITY_DISPLAY = {
  PROFILE_VIEW: "profile_view",
  COMMUNITY_VIEW: "community_view",
} as const;

export type DisplayOptionFieldInputs =
  (typeof DISPLAY_OPTION_FIELD_INPUTS)[keyof typeof DISPLAY_OPTION_FIELD_INPUTS];

export const FIELD_TYPES = {
  TEXT: "text",
  TEXTAREA: "textarea",
  SELECT: "select",
  LINK: "link",
  NUMBER: "number",
  CHECKBOX: "checkbox",
  LOCATION: "location",
} as const;

export type FieldTypes = (typeof FIELD_TYPES)[keyof typeof FIELD_TYPES];

export const FIELD_ICONS = {
  [FIELD_TYPES.TEXT]: "16-character-square",
  [FIELD_TYPES.SELECT]: "16-select-square",
  [FIELD_TYPES.CHECKBOX]: "16-checkbox-square",
  [FIELD_TYPES.TEXTAREA]: "16-text-square",
  [FIELD_TYPES.LINK]: "link",
  [FIELD_TYPES.NUMBER]: "16-number-square",
  email: "16-character-square",
  timezone: "16-social-web",
  date: "16-calendar",
  instagram: "16-instagram-square",
  facebook: "16-facebook-square",
  twitter: "16-twitter-square",
  linkedin: "16-linkedin-square",
} as const;

export type FieldIcons = (typeof FIELD_ICONS)[keyof typeof FIELD_ICONS];

export const FIELD_TYPES_TO_BACKEND_VALUE_PARAM = {
  [FIELD_TYPES.TEXT]: "text",
  [FIELD_TYPES.LINK]: "text",
  [FIELD_TYPES.TEXTAREA]: "textarea",
  [FIELD_TYPES.NUMBER]: "text",
  [FIELD_TYPES.SELECT]: "community_member_choices_attributes",
  [FIELD_TYPES.CHECKBOX]: "community_member_choices_attributes",
  [FIELD_TYPES.LOCATION]: "payload",
} as const;

/**
 * These field types have choices built into them.
 */
export const choiceAttributeFieldTypes = [
  FIELD_TYPES.SELECT,
  FIELD_TYPES.CHECKBOX,
] as const;

interface GetCustomFieldNameParams {
  index: number;
  fieldType: FieldTypes;
}

export const getCustomFieldName = ({
  index,
  fieldType,
}: GetCustomFieldNameParams) =>
  `community_member_profile_fields_attributes.${index}.${FIELD_TYPES_TO_BACKEND_VALUE_PARAM[fieldType]}`;

export const NUMERIC_FORMATS = {
  NUMBER: "number",
  PERCENT: "percentage",
  CUSTOM: "custom",
  UNFORMATTED: "unformatted",
} as const;

export type NumericFormats =
  (typeof NUMERIC_FORMATS)[keyof typeof NUMERIC_FORMATS];
